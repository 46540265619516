import React, { useContext, useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { Transition } from "@headlessui/react"
import { InstantSearch, Configure } from "react-instantsearch-dom"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { navigate } from "@reach/router"
import Div100vh from "react-div-100vh"
import { useCookies } from "react-cookie"
import { ViewListIcon, ViewGridIcon } from "@heroicons/react/solid"
import { MenuAlt2Icon } from "@heroicons/react/outline"

import {
  searchClient,
  searchStateToUrl,
  urlToSearchState,
  DEBOUNCE_TIME,
} from "../../utils/algolia/searchClient"
import { Locale } from "../../context"

import Layout from "../../components/Layout"
import { Saved, Currency } from "../../components/utils"
import {
  CustomHits,
  CustomStats,
  CustomPagination,
  CustomSearchBox,
} from "../../components/search"

import Logo from "../../images/gadait-logo.inline.svg"
import Close from "../../images/close.inline.svg"

import Title from "./Title"
import Filters from "./Filters"

import "instantsearch.css/themes/reset.css"

const Index = ({ data, location }) => {
  let { locale } = useContext(Locale)
  const [cookies, setCookie] = useCookies(['all', 'view'])

  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const [debouncedSetState, setDebouncedSetState] = useState()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  
  useEffect(() => {
    if (location.search.includes("all=")) {
      setCookie("all", true, { path: '/', maxAge: 2147483647 })
    }
  }, [location.search, setCookie])

  useEffect(() => {
    return () => clearTimeout(debouncedSetState)
  }, [debouncedSetState])

  const onSearchStateChange = updatedSearchState => {
    delete updatedSearchState["configure"]
    clearTimeout(debouncedSetState)

    console.log('onSearchStateChange:', { 
      updatedSearchState, 
      locale,
      currentPath: window.location.pathname 
    })

    setDebouncedSetState(
      setTimeout(() => {
        const currentLocale = window.location.pathname.split('/')[1] || locale
        const newUrl = `/${currentLocale}/${data.search.slug}/` + searchStateToUrl(updatedSearchState, currentLocale)
        
        console.log('Navigating to:', newUrl)
        
        navigate(newUrl, { replace: true })
      }, DEBOUNCE_TIME)
    )

    setSearchState(updatedSearchState)
  }

  return (
    <Layout hideHeader hideFooter hideNewsletter>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.search.locale,
        }}
        seo={data.search.seoMetaTags}
      >
        {data.search._allSlugLocales.map((version, index) => (
          <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${version.value}`} key={index} />
        ))}
      </HelmetDatoCms>
      <InstantSearch
        searchClient={searchClient}
        indexName={`${process.env.GATSBY_ALGOLIA_INDEX_NAME}_${locale}`}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
      >
        <Configure filters={`NOT propertyStatus.originalId:34805497 AND NOT propertyStatus.originalId:34805527 ${!cookies.all ? `AND NOT websiteHide:true` : ``}`} hitsPerPage={12} />
        <Div100vh className="flex overflow-hidden bg-gray-100">
          <Transition show={isSidebarOpen} unmount={false}>
            <div className="md:hidden">
              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="fixed inset-0"
                  aria-hidden="true"
                  onClick={() => setIsSidebarOpen(false)}
                  unmount={false}
                >
                  <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                </Transition.Child>
                <Transition.Child
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                  className="relative flex flex-col flex-1 w-full max-w-sm bg-white"
                  unmount={false}
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <Transition.Child
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="flex items-center justify-center w-10 h-10 ml-1 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      <Close />
                    </Transition.Child>
                  </div>
                  <div className="flex-1 h-screen px-4 pt-4 pb-4 overflow-y-auto">
                    <Link to={`/${locale}`} aria-label="iKeys Realty">
                      <Logo className="inline-block w-auto h-9 text-blue" />
                    </Link>
                    <div className="mt-5">
                      <Filters data={data.search} />
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
              </div>
            </div>
          </Transition>
          <div className="hidden md:flex md:flex-shrink-0">
            <div className="flex flex-col max-w-xs">
              <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-white border-r border-gray-200">
                <div className="flex items-center flex-shrink-0 px-4">
                  <Link to={`/${locale}`} aria-label="iKeys Realty">
                    <Logo className="inline-block w-auto h-9 text-blue" />
                  </Link>
                </div>
                <div className="px-4 mt-6 bg-white">
                  <Filters data={data.search} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 w-0 overflow-hidden">
            <div className="relative z-10 flex flex-shrink-0 h-16 bg-white shadow">
              <button
                className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setIsSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="w-6 h-6" />
              </button>
              <div className="flex justify-between flex-1 px-4">
                <div className="flex flex-1">
                  <CustomSearchBox placeholder={data.search.searchPlaceholder} />
                </div>
                <div className="flex items-center ml-4 md:ml-6">
                  <Saved small />
                  <Currency small />
                </div>
              </div>
            </div>
            <main className="relative flex-1 overflow-y-auto focus:outline-none">
              <div className="py-6">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                  <div className="flex flex-wrap items-center justify-between">
                    <div className="mb-2">
                      <Title data={data.search} />
                    </div>
                    <div className="flex items-center mb-2">
                      <CustomStats data={data.search} />
                      <div className="hidden ml-4 bg-gray-100 p-0.5 rounded-lg items-center xl:flex">
                        <button
                          type="button"
                          className={`p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black ${cookies.view !== 'grid' ? `bg-white shadow-sm` : `hover:bg-gray-50 hover:shadow-sm`}`}
                          onClick={() => setCookie("view", "list", { path: '/', maxAge: 2147483647 })}
                        >
                          <ViewListIcon className="w-5 h-5" />
                          <span className="sr-only">Use list view</span>
                        </button>
                        <button
                          type="button"
                          className={`ml-0.5 p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black ${cookies.view === 'grid' ? `bg-white shadow-sm` : `hover:bg-gray-50 hover:shadow-sm`}`}
                          onClick={() => setCookie("view", "grid", { path: '/', maxAge: 2147483647 })}
                        >
                          <ViewGridIcon className="w-5 h-5" />
                          <span className="sr-only">Use grid view</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <CustomHits listView={cookies.view !== "grid"} />
                  </div>
                  <CustomPagination
                    data={data.search}
                    searchState={searchState}
                    locale={locale}
                  />
                </div>
              </div>
            </main>
          </div>
        </Div100vh>
      </InstantSearch>
    </Layout>
  )
}

export const query = graphql`
  query SearchQuery($locale: String!) {
    search: datoCmsSearch(locale: { eq: $locale }) {
      ...Search
    }
  }
`

export default Index
